<template>
  <div class="dash">
    <div class="alert">
      <span>
        the values are in <strong>BRL</strong>, if your purchase is
        international, please open a ticket on <strong>discord</strong>.
      </span>
    </div>

    <section id="heading" class="container">
      <h2>
        mushi mushi
        <small>
          if you need a legit and undetected csgo cheat you are in the right
          place</small
        >
      </h2>
    </section>

    <div id="products" class="container article">
      <div class="row">
        <div class="col-sm-12 col-md-4 sm-space">
          <div class="buy_card">
            <div class="content">
              <h2>
                monthly
                <small>45,00 BRL</small>
              </h2>
            </div>

            <img
              src="/subs/plan1.jpg"
              alt=""
              class="buy_card_img"
            />

            <div class="info">
              <h3>
                monthly plan, want to know our product?
              </h3>
              <button>buy monthly</button>
            </div>
          </div>
        </div>

        <div class="col-sm-12 col-md-4 sm-space">
          <div class="buy_card">
            <div class="content">
              <h2>
                quarterly
                <small>85,00 BRL</small>
              </h2>
            </div>

            <img
              src="/subs/plan2.jpg"
              alt=""
              class="buy_card_img"
            />

            <div class="info">
              <h3>
                nice quarterly, how about buying lifetime?
              </h3>
              <button>buy quarterly</button>
            </div>
          </div>
        </div>

        <div class="col-sm-12 col-md-4 sm-space">
          <div class="buy_card">
            <div class="content">
              <h2>
                lifetime
                <small>125,00 BRL</small>
              </h2>
            </div>

            <img
              src="/subs/plan3.png"
              alt=""
              class="buy_card_img"
            />

            <div class="info">
              <h3>
                lifetime mushi mushi, get forever cute cute
              </h3>
              <button>buy lifetime</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "@/assets/css/dash.css";

export default {
  name: "Buy",
};
</script>

<style scoped>
.buy_card {
  max-width: 90%;
  margin: 0 auto;
}

.buy_card .content {
  padding: 5px 15px 25px;
  margin: 0 0 -20px;
  background-color: var(--card-back-color);
}

.buy_card .content,
.buy_card_img {
  border-radius: 20px;
}

.buy_card_img {
  width: 100%;
  height: 260px;
  object-fit: cover;
}

.buy_card .info {
  text-align: center;
  position: relative;
  border-radius: 20px;
  padding: 20px;
  margin: -230px auto 10px;
  width: 300px;
  display: none;
  cursor: pointer;
  color: #fff;
}

.buy_card:hover img {
  opacity: 0.5;
  -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
}

.buy_card:hover .info {
  display: block;
}

@media screen and (max-width: 767px) {
  .buy_card .info {
    margin-bottom: 60px;
  }

  .sm-space:last-child .info {
    margin-bottom: 10px;
  }
}
</style>
